body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main {
  padding-bottom: 50px;
  min-height: 100vh;
}

.transcript {
  font-family: 'Marck Script', 'Dancing Script', cursive;
  font-size: 1.6em;

  @media screen and (min-width: 760px) {
    font-size: 1.8em;
  }

  max-width: 800px;

  p {
    margin-top: 12px;
    margin-bottom: 12px;
    text-indent: 30px;
  }
}
